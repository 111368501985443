.anchor-to-top {
  z-index: -2000;
  position: fixed;
  background-color: var(--anchor);
  bottom: 15%;
  right: 10%;
  opacity: 0;
  cursor: pointer;
  transition: 0.5s ease;
  border-radius: 5px;
}

.show {
  z-index: 2000;
  opacity: 1;
}

.anchor-to-top img{
  margin-top: 8px;
  width: 35px;
}


@media (hover: hover), (-ms-high-contrast:none) {
  .anchor-to-top:hover {
    background-color: white;
    transform: scale(1.1);
  }
}